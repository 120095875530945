.phone {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url(../images/phone-white.svg);

  &__screen {
    margin: 0;
    padding: 0 34px 0 42px;
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;

    &--show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__name {
    margin: 2px 18px;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
    font-size: .875rem;
    line-height: 1.25;
  }

  &__sms {
    @include paragraphText;

    display: block;
    padding: 16px 18px 20px;
    min-width: 105px;
    max-width: 100%;
    border-radius: 12px 12px 12px 0;
    background-color: $colorBackgroundLight;

    box-shadow: 6px 6px 9px 0 rgba(0, 0, 0, 0.14);
  }
}