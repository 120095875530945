.logo {
  &__image {
    display: block;
    height: 18px;

    @include onTablet {
      height: 24px;
    }
  }
}
