@import 'utils/variables';
@import 'utils/mixins';

@import 'blocks/container';
@import "blocks/icon";
@import "blocks/logo";
@import "blocks/grid";
@import 'blocks/button';
@import 'blocks/spoller-block';

@import 'blocks/page';
@import 'blocks/header';
@import 'blocks/menu';
@import 'blocks/advantages';
@import 'blocks/card';
@import 'blocks/phone-section';
@import 'blocks/phone';
@import './blocks/services';
@import './blocks/service-card';
@import 'blocks/our-standard';
@import 'blocks/code-block';
@import 'blocks/everything-you-need';
@import 'blocks/faq';
@import 'blocks/contact-us';
@import 'blocks/form2';
