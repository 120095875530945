.services {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onDesktop {
      flex-direction: row;
      // align-items: left;
      justify-content: space-between;
    }
  }

  &__description {
    // margin: auto;

    @include onDesktop {
      width: calc((100% - 100px) * 0.4);
    }
  }

  &__paragraph {
    @include paragraphText;
    margin: 0 0 40px;

    @include onTablet {
      margin-bottom: 56px;
    }
  }

  &__cards {
    box-sizing: border-box;
    // width: 100%;
    max-width: 650px;
    // height: 400px;
    height: 100%;
    // margin: 9px;
    // margin: auto;
    padding: 30px 50px 30px;
    // padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
    gap: 8px;

    background: rgb(80,129,193);
    background: linear-gradient(90deg, rgba(80,129,193,0.5) 0%, rgba(76,138,196,0.25) 18%, rgba(57,186,213,0.3) 100%);
    background-position: center;
    background: linear-gradient(90deg, rgba(80,129,193,0.35) 0%, rgba(76,138,196,0.12) 20%, rgba(57,186,213,0.15) 100%);
    background-position: center;
    border-radius: 64% 36% 62% 38% / 27% 66% 34% 73% ;

    @include onTablet {
      padding: 16px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      gap: 15px;
    }

    @include onDesktop {
      width: calc((100% - 100px) * 0.6);
    }
  }

  &__card {
    box-sizing: border-box;
    width: 100%;
    height: max-content;
    padding: 15px 15px 20px;

    @include onTablet {
      padding: 25px;

      width: 230px;
      height: 290px;
    }

    @include onDesktop {
      max-width: 230px;
      width: 45%;
    }
  }
}