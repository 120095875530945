.advantages {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    margin: 0 10px 30px 10px;

    @include onDesktop {
      margin: 0 20px 50px 20px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1040px;
    margin: 0 auto 20px;
  }

  &__card {
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 15px;
    width: 100%;

    @include onTablet {
      width: calc(100% / 2);
    }

    @include onDesktop {
      width: calc(100% / 3);
      padding: 0 15px;
    }
  }
}