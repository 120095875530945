.menu {
  background-color: $colorBlue;

  overflow-y: auto;

  &__content {
    box-sizing: border-box;
    height: 100vh;
    margin: 0;
    padding: $headerTopPaddingMobile 0;

    @include onDesktop {
      padding: $headerTopPaddingTablet 0;
    }

    @include onBigDesktop {
      // margin: 0 $horisontalMarginHeader;
    }
  }

  &__top {
    margin-bottom: 80px;
    @include top-actions;

    @include onDesktop {
      margin-bottom: 74px;
    }
  }

  &__list {
    margin: 0 0 40px;
    padding: 0;
    list-style: none;
    text-align: center;
  }

  &__item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: inline-block;
    padding-bottom: 8px;
    border-bottom: 1px solid transparent;

    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;

    transition: border-color 0.3s;

    @include onDesktop {
      font-weight: 700;
    }

    &:hover {
      border-color: #fff;
    }
  }

  &__call {
    display: block;
    width: max-content;
    margin: auto;

    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;

    @include onDesktop {
      font-weight: 700;
    }

    &--phone {
      padding-bottom: 16px;
      line-height: 27px;
    }

    &--action {
      padding-bottom: 8px;
      border-bottom: 1px solid #fff;

      font-size: 12px;
      line-height: 16px;
      letter-spacing: 2px;

      transition: border-bottom 0.3s;

      &:hover {
        border-color: transparent;
      }
    }
  }
}
