.spoller-block {
  &--init &__title {
    cursor: pointer;
    // padding: 10px 40px 10px 10px;
    // margin-bottom: 0;

    &::after,
    &::before {
      content: "";
      width: 20px;
      height: 1px;
      background-color: $colorBlue;
      position: absolute;
      right: 0px;
      top: 50%;
      transition: transform 0.3s ease 0s;
    }

    &--white-mark {
      &::after,
      &::before {
        // background-color: #fff;
        background-color: #f7aae5;
      }
    }

    &::after {
      transform: rotate(-90deg);
    }

    &--active::after {
      transform: rotate(0deg);
    }
  }
}