.form {

  &__body {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;

    background: #fff;
    border-radius: 12px;
    padding: 35px;
    // min-height: 460px;
    width: 100%;
    max-width: 550px;
    // min-width: 470px;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.14);


    @include onDesktop {
      max-width: 470px;
      min-width: 400px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(51, 51, 51, 0.9) url("../images/loading.gif") center / 50px no-repeat;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease 0s;
    }

    &._sending::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &__item {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    // clear: left;
    // line-height: 1.4em;
    margin: 3px 5px;
    padding: 10px 2px 2px;

    width: 100%;

    &--first-name {
      width: calc(50% - 10px);
      display: inline;
      padding-right: 5px;
      // margin-bottom: 0px;
    }

    &--last-name {
      width: calc(50% - 10px);
      display: inline;
      padding-left: 5px;
      // margin-bottom: 0px;
    }
  }

  &__label {
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 0;
    z-index: 2;

    text-align: left;
    width: auto;
    font-size: 14px;
    line-height: 1.5;
    color: #666;
    letter-spacing: 0;
    font-weight: 400;
    padding: 2px 5px;
    background: #fff;
  }

  &__input {
    box-sizing: border-box;
    // width: 100% !important;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    line-height: 1.333;
    color: #242525;
    padding: 12px 14px;
    background-color: #fff;
    letter-spacing: 0;
    font-weight: 400;

    appearance: none;

    &--textarea {
      min-height: 120px;
      resize: vertical;
    }

    &._error {
      border: 1px solid #ff0000;
    }
  }

  &__select {
    box-sizing: border-box;
    width: 100%;
    padding: 12px 24px 12px 14px;

    border: 1px solid #ccc;
    border-radius: 4px;

    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
    background-size: 12px auto;


    font-size: 18px;
    line-height: 1.333;
    color: #242525;
    letter-spacing: 0;
    font-weight: 400;
  }

  &__button {
    margin: 10px 5px;
    background-color: $colorBlue;
    color: #fff;

    &::before {
      content: "";
      background-color: #fff;
    }

    &:hover {
      color: $colorBlue;
    }
  }
}