.header {
  &__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    // border: 1px solid red;

    min-height: 100vh;
    padding: $headerTopPaddingMobile 0 40px;
    // margin-bottom: 20px;

    @include onTablet {
      // padding: $headerTopPaddingTablet 0 70px;
    }

    @include onDesktop {
      // padding-bottom: 50px;
      // margin-bottom: 20px;

      @media (max-height: 900px) {
        min-height: 100vh;
      }

      @media (min-height: 900px) {
        min-height: 900px;
      }
    }
  }

  &__top {
    @include top-actions;
  }

  &__bottom {
    display: flex;
    flex-direction: column-reverse;
    // justify-content: space-evenly;
    // height: 100%;
    margin: auto 0;

    @include onDesktop {
      display: grid;
      margin: auto 0;
    }
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    // margin: auto;
    // height: 100%;

    @include onTablet {
      padding: 0 50px;
    }

    @include onDesktop {
      margin: 0;
      padding: 0;
      height: 100%;
      max-width: 520px;
      align-items: start;
      justify-content: center;
    }
  }

  &__sub-title {
    margin: 0 0 18px;
    padding: 0;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 2px;
    opacity: 0.8;
    // background-color: transparent;
    text-transform: uppercase;
    // text-rendering: optimizeLegibility;
    // -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;

    @include onDesktop {
      margin-bottom: 22px;
    }
  }

  &__title {
    -webkit-font-smoothing: antialiased;
    margin: 0 0 28px;
    padding: 0;
    letter-spacing: -0.5px;
    font-weight: 400;
    font-size: 2rem;
    line-height: 100%;
    text-align: center;

    @include onBigMobile {
      font-size: 2.4rem;
    }

    @include onTablet {
      margin-bottom: 42px;
      font-size: 3rem;
    }


    @include onDesktop {
      margin-bottom: 48px;
      // font-size: 4rem;
      font-size: 4rem;
      text-align: left;
    }

    @media (min-width: 1024px) and (max-width: 1150px) {
      font-size: 3.4rem;
    }
  }

  &__description {
    @include paragraphText;

    margin-bottom: 26px;
    text-align: center;

    @include onDesktop {
      margin-bottom: 30px;
      text-align: left;
    }
  }

  &__animation {
    height: 35vh;
    width: 100%;
    margin-bottom: 40px;
    // margin: 14px auto;

    @include onTablet {
      margin-bottom: 60px;
      height: 48vh;
    }

    @include onDesktop {
      display: flex;
      flex-direction: column;

      margin: auto 0;
      width: 100%;
      min-width: none;
      max-width: none;
      height: 100%;
      justify-content: center;
    }
  }
}