// @mixin onMobileAndTablet {
//   @media (max-width: 1023px) {
//     @content;
//   }
// }

@mixin onBigMobile {
  @media (min-width: 460px) {
    @content;
  }
}

@mixin onTablet {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin onBigDesktop {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin top-actions {
  --icon-width: 18px;

  display: grid;
  grid-template-columns: 1fr var(--icon-width) var(--icon-width);
  justify-items: start;
  align-items: center;
  column-gap: 30px;

  @include onTablet {
    --icon-width: 24px;

    column-gap: 40px;
  }
}

@mixin paragraphText {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 24px;
  // text-rendering: optimizeLegibility;
  color: $colorText;
  -webkit-font-smoothing: antialiased;
}