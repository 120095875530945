.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (width >= 640px) {
  .container {
    padding: 0 30px;
  }
}

@media (width >= 1024px) {
  .container {
    padding: 0 40px;
  }
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding-bottom: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

.icon--phone {
  background-image: url("phone.d38c1760.svg");
}

.icon__tooltip {
  letter-spacing: 1px;
  color: #0078db;
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  width: max-content;
  padding-right: 18px;
  font-weight: 700;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.icon:hover .icon__tooltip {
  opacity: 1;
  pointer-events: all;
}

.icon--menu {
  background-image: url("menu.751df960.svg");
}

.icon--menu:hover {
  background-image: url("menu-hover.193478dc.svg");
}

.icon--cross {
  background-image: url("cross.d4237fba.svg");
  grid-column: -2 / -1;
}

.icon--chat {
  background-image: url("chat.4a1cb3e9.svg");
  width: 26px;
  height: 26px;
  padding: 0;
  position: absolute;
  transform: translate(-32px, 18px);
}

.logo__image {
  height: 18px;
  display: block;
}

@media (width >= 640px) {
  .logo__image {
    height: 24px;
  }
}

.grid {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  display: grid;
}

@media (width >= 640px) {
  .grid {
    grid-template-columns: repeat(8, 1fr);
    column-gap: 24px;
  }
}

@media (width >= 1024px) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
}

.grid--tablet {
  display: block;
}

@media (width >= 640px) {
  .grid--tablet {
    display: grid;
  }
}

.grid--desktop {
  display: block;
}

@media (width >= 1024px) {
  .grid--desktop {
    display: grid;
  }
}

@media (width >= 640px) {
  .grid__item--tablet-1-1 {
    grid-column: 1 / 2;
  }

  .grid__item--tablet-1-2 {
    grid-column: 1 / 3;
  }

  .grid__item--tablet-1-3 {
    grid-column: 1 / 4;
  }

  .grid__item--tablet-1-4 {
    grid-column: 1 / 5;
  }

  .grid__item--tablet-1-5 {
    grid-column: 1 / 6;
  }

  .grid__item--tablet-1-6 {
    grid-column: 1 / 7;
  }

  .grid__item--tablet-1-7 {
    grid-column: 1 / 8;
  }

  .grid__item--tablet-1-8 {
    grid-column: 1 / 9;
  }

  .grid__item--tablet-2-2 {
    grid-column: 2 / 3;
  }

  .grid__item--tablet-2-3 {
    grid-column: 2 / 4;
  }

  .grid__item--tablet-2-4 {
    grid-column: 2 / 5;
  }

  .grid__item--tablet-2-5 {
    grid-column: 2 / 6;
  }

  .grid__item--tablet-2-6 {
    grid-column: 2 / 7;
  }

  .grid__item--tablet-2-7 {
    grid-column: 2 / 8;
  }

  .grid__item--tablet-2-8 {
    grid-column: 2 / 9;
  }

  .grid__item--tablet-3-3 {
    grid-column: 3 / 4;
  }

  .grid__item--tablet-3-4 {
    grid-column: 3 / 5;
  }

  .grid__item--tablet-3-5 {
    grid-column: 3 / 6;
  }

  .grid__item--tablet-3-6 {
    grid-column: 3 / 7;
  }

  .grid__item--tablet-3-7 {
    grid-column: 3 / 8;
  }

  .grid__item--tablet-3-8 {
    grid-column: 3 / 9;
  }

  .grid__item--tablet-4-4 {
    grid-column: 4 / 5;
  }

  .grid__item--tablet-4-5 {
    grid-column: 4 / 6;
  }

  .grid__item--tablet-4-6 {
    grid-column: 4 / 7;
  }

  .grid__item--tablet-4-7 {
    grid-column: 4 / 8;
  }

  .grid__item--tablet-4-8 {
    grid-column: 4 / 9;
  }

  .grid__item--tablet-5-5 {
    grid-column: 5 / 6;
  }

  .grid__item--tablet-5-6 {
    grid-column: 5 / 7;
  }

  .grid__item--tablet-5-7 {
    grid-column: 5 / 8;
  }

  .grid__item--tablet-5-8 {
    grid-column: 5 / 9;
  }

  .grid__item--tablet-6-6 {
    grid-column: 6 / 7;
  }

  .grid__item--tablet-6-7 {
    grid-column: 6 / 8;
  }

  .grid__item--tablet-6-8 {
    grid-column: 6 / 9;
  }

  .grid__item--tablet-7-7 {
    grid-column: 7 / 8;
  }

  .grid__item--tablet-7-8 {
    grid-column: 7 / 9;
  }

  .grid__item--tablet-8-8 {
    grid-column: 8 / 9;
  }
}

@media (width >= 1024px) {
  .grid__item--desktop-1-1 {
    grid-column: 1 / 2;
  }

  .grid__item--desktop-1-2 {
    grid-column: 1 / 3;
  }

  .grid__item--desktop-1-3 {
    grid-column: 1 / 4;
  }

  .grid__item--desktop-1-4 {
    grid-column: 1 / 5;
  }

  .grid__item--desktop-1-5 {
    grid-column: 1 / 6;
  }

  .grid__item--desktop-1-6 {
    grid-column: 1 / 7;
  }

  .grid__item--desktop-1-7 {
    grid-column: 1 / 8;
  }

  .grid__item--desktop-1-8 {
    grid-column: 1 / 9;
  }

  .grid__item--desktop-1-9 {
    grid-column: 1 / 10;
  }

  .grid__item--desktop-1-10 {
    grid-column: 1 / 11;
  }

  .grid__item--desktop-1-11 {
    grid-column: 1 / 12;
  }

  .grid__item--desktop-1-12 {
    grid-column: 1 / 13;
  }

  .grid__item--desktop-2-2 {
    grid-column: 2 / 3;
  }

  .grid__item--desktop-2-3 {
    grid-column: 2 / 4;
  }

  .grid__item--desktop-2-4 {
    grid-column: 2 / 5;
  }

  .grid__item--desktop-2-5 {
    grid-column: 2 / 6;
  }

  .grid__item--desktop-2-6 {
    grid-column: 2 / 7;
  }

  .grid__item--desktop-2-7 {
    grid-column: 2 / 8;
  }

  .grid__item--desktop-2-8 {
    grid-column: 2 / 9;
  }

  .grid__item--desktop-2-9 {
    grid-column: 2 / 10;
  }

  .grid__item--desktop-2-10 {
    grid-column: 2 / 11;
  }

  .grid__item--desktop-2-11 {
    grid-column: 2 / 12;
  }

  .grid__item--desktop-2-12 {
    grid-column: 2 / 13;
  }

  .grid__item--desktop-3-3 {
    grid-column: 3 / 4;
  }

  .grid__item--desktop-3-4 {
    grid-column: 3 / 5;
  }

  .grid__item--desktop-3-5 {
    grid-column: 3 / 6;
  }

  .grid__item--desktop-3-6 {
    grid-column: 3 / 7;
  }

  .grid__item--desktop-3-7 {
    grid-column: 3 / 8;
  }

  .grid__item--desktop-3-8 {
    grid-column: 3 / 9;
  }

  .grid__item--desktop-3-9 {
    grid-column: 3 / 10;
  }

  .grid__item--desktop-3-10 {
    grid-column: 3 / 11;
  }

  .grid__item--desktop-3-11 {
    grid-column: 3 / 12;
  }

  .grid__item--desktop-3-12 {
    grid-column: 3 / 13;
  }

  .grid__item--desktop-4-4 {
    grid-column: 4 / 5;
  }

  .grid__item--desktop-4-5 {
    grid-column: 4 / 6;
  }

  .grid__item--desktop-4-6 {
    grid-column: 4 / 7;
  }

  .grid__item--desktop-4-7 {
    grid-column: 4 / 8;
  }

  .grid__item--desktop-4-8 {
    grid-column: 4 / 9;
  }

  .grid__item--desktop-4-9 {
    grid-column: 4 / 10;
  }

  .grid__item--desktop-4-10 {
    grid-column: 4 / 11;
  }

  .grid__item--desktop-4-11 {
    grid-column: 4 / 12;
  }

  .grid__item--desktop-4-12 {
    grid-column: 4 / 13;
  }

  .grid__item--desktop-5-5 {
    grid-column: 5 / 6;
  }

  .grid__item--desktop-5-6 {
    grid-column: 5 / 7;
  }

  .grid__item--desktop-5-7 {
    grid-column: 5 / 8;
  }

  .grid__item--desktop-5-8 {
    grid-column: 5 / 9;
  }

  .grid__item--desktop-5-9 {
    grid-column: 5 / 10;
  }

  .grid__item--desktop-5-10 {
    grid-column: 5 / 11;
  }

  .grid__item--desktop-5-11 {
    grid-column: 5 / 12;
  }

  .grid__item--desktop-5-12 {
    grid-column: 5 / 13;
  }

  .grid__item--desktop-6-6 {
    grid-column: 6 / 7;
  }

  .grid__item--desktop-6-7 {
    grid-column: 6 / 8;
  }

  .grid__item--desktop-6-8 {
    grid-column: 6 / 9;
  }

  .grid__item--desktop-6-9 {
    grid-column: 6 / 10;
  }

  .grid__item--desktop-6-10 {
    grid-column: 6 / 11;
  }

  .grid__item--desktop-6-11 {
    grid-column: 6 / 12;
  }

  .grid__item--desktop-6-12 {
    grid-column: 6 / 13;
  }

  .grid__item--desktop-7-7 {
    grid-column: 7 / 8;
  }

  .grid__item--desktop-7-8 {
    grid-column: 7 / 9;
  }

  .grid__item--desktop-7-9 {
    grid-column: 7 / 10;
  }

  .grid__item--desktop-7-10 {
    grid-column: 7 / 11;
  }

  .grid__item--desktop-7-11 {
    grid-column: 7 / 12;
  }

  .grid__item--desktop-7-12 {
    grid-column: 7 / 13;
  }

  .grid__item--desktop-8-8 {
    grid-column: 8 / 9;
  }

  .grid__item--desktop-8-9 {
    grid-column: 8 / 10;
  }

  .grid__item--desktop-8-10 {
    grid-column: 8 / 11;
  }

  .grid__item--desktop-8-11 {
    grid-column: 8 / 12;
  }

  .grid__item--desktop-8-12 {
    grid-column: 8 / 13;
  }

  .grid__item--desktop-9-9 {
    grid-column: 9 / 10;
  }

  .grid__item--desktop-9-10 {
    grid-column: 9 / 11;
  }

  .grid__item--desktop-9-11 {
    grid-column: 9 / 12;
  }

  .grid__item--desktop-9-12 {
    grid-column: 9 / 13;
  }

  .grid__item--desktop-10-10 {
    grid-column: 10 / 11;
  }

  .grid__item--desktop-10-11 {
    grid-column: 10 / 12;
  }

  .grid__item--desktop-10-12 {
    grid-column: 10 / 13;
  }

  .grid__item--desktop-11-11 {
    grid-column: 11 / 12;
  }

  .grid__item--desktop-11-12 {
    grid-column: 11 / 13;
  }

  .grid__item--desktop-12-12 {
    grid-column: 12 / 13;
  }
}

.button {
  box-sizing: border-box;
  font: inherit;
  color: #0078db;
  cursor: pointer;
  z-index: 1;
  background-color: #0000;
  border: 2px solid #0078db;
  border-radius: 2px;
  width: max-content;
  padding: 14px 28px;
  font-weight: 700;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.button:before {
  content: "";
  z-index: -1;
  background-color: #0078db;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.button:hover {
  color: #fff;
}

.button:hover:before {
  transform: translateX(0);
}

.button--light {
  background-color: #fff;
  border-color: #fff;
}

.spoller-block--init .spoller-block__title {
  cursor: pointer;
}

.spoller-block--init .spoller-block__title:after, .spoller-block--init .spoller-block__title:before {
  content: "";
  background-color: #0078db;
  width: 20px;
  height: 1px;
  transition: transform .3s;
  position: absolute;
  top: 50%;
  right: 0;
}

.spoller-block--init .spoller-block__title--white-mark:after, .spoller-block--init .spoller-block__title--white-mark:before {
  background-color: #f7aae5;
}

.spoller-block--init .spoller-block__title:after {
  transform: rotate(-90deg);
}

.spoller-block--init .spoller-block__title--active:after {
  transform: rotate(0);
}

.page {
  color: #000;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.page__body {
  background-image: url("header-background.648bdabb.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0;
}

@media (width >= 1024px) {
  .page__body {
    background-size: 55%;
  }
}

.page__body--with-menu {
  overflow: hidden;
}

.page__menu {
  transition: transform .3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

.page__menu:target {
  transform: translateX(0);
}

.page__section {
  padding: 30px 0;
}

@media (width >= 1024px) {
  .page__section {
    margin: 0 30px;
    padding: 60px 0;
  }
}

.page__section-title {
  text-rendering: optimizelegibility;
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 32px;
  padding: 0;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
}

@media (width >= 640px) {
  .page__section-title {
    font-size: 2.625rem;
  }
}

.page__section-title--white {
  color: #fff;
}

.header__content {
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 26px 0 40px;
  display: flex;
}

@media (width >= 1024px) and (height <= 900px) {
  .header__content {
    min-height: 100vh;
  }
}

@media (width >= 1024px) and (height >= 900px) {
  .header__content {
    min-height: 900px;
  }
}

.header__top {
  --icon-width: 18px;
  grid-template-columns: 1fr var(--icon-width) var(--icon-width);
  place-items: center start;
  column-gap: 30px;
  display: grid;
}

@media (width >= 640px) {
  .header__top {
    --icon-width: 24px;
    column-gap: 40px;
  }
}

.header__bottom {
  flex-direction: column-reverse;
  margin: auto 0;
  display: flex;
}

@media (width >= 1024px) {
  .header__bottom {
    margin: auto 0;
    display: grid;
  }
}

.header__bottom-content {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media (width >= 640px) {
  .header__bottom-content {
    padding: 0 50px;
  }
}

@media (width >= 1024px) {
  .header__bottom-content {
    justify-content: center;
    align-items: start;
    max-width: 520px;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

.header__sub-title {
  letter-spacing: 2px;
  opacity: .8;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 18px;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
}

@media (width >= 1024px) {
  .header__sub-title {
    margin-bottom: 22px;
  }
}

.header__title {
  -webkit-font-smoothing: antialiased;
  letter-spacing: -.5px;
  text-align: center;
  margin: 0 0 28px;
  padding: 0;
  font-size: 2rem;
  font-weight: 400;
  line-height: 100%;
}

@media (width >= 460px) {
  .header__title {
    font-size: 2.4rem;
  }
}

@media (width >= 640px) {
  .header__title {
    margin-bottom: 42px;
    font-size: 3rem;
  }
}

@media (width >= 1024px) {
  .header__title {
    text-align: left;
    margin-bottom: 48px;
    font-size: 4rem;
  }
}

@media (width >= 1024px) and (width <= 1150px) {
  .header__title {
    font-size: 3.4rem;
  }
}

.header__description {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 0 0 26px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (width >= 1024px) {
  .header__description {
    text-align: left;
    margin-bottom: 30px;
  }
}

.header__animation {
  width: 100%;
  height: 35vh;
  margin-bottom: 40px;
}

@media (width >= 640px) {
  .header__animation {
    height: 48vh;
    margin-bottom: 60px;
  }
}

@media (width >= 1024px) {
  .header__animation {
    min-width: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: none;
    height: 100%;
    margin: auto 0;
    display: flex;
  }
}

.menu {
  background-color: #0078db;
  overflow-y: auto;
}

.menu__content {
  box-sizing: border-box;
  height: 100vh;
  margin: 0;
  padding: 26px 0;
}

@media (width >= 1024px) {
  .menu__content {
    padding: 30px 0;
  }
}

.menu__top {
  --icon-width: 18px;
  grid-template-columns: 1fr var(--icon-width) var(--icon-width);
  place-items: center start;
  column-gap: 30px;
  margin-bottom: 80px;
  display: grid;
}

@media (width >= 640px) {
  .menu__top {
    --icon-width: 24px;
    column-gap: 40px;
  }
}

@media (width >= 1024px) {
  .menu__top {
    margin-bottom: 74px;
  }
}

.menu__list {
  text-align: center;
  margin: 0 0 40px;
  padding: 0;
  list-style: none;
}

.menu__item {
  margin-bottom: 24px;
}

.menu__item:last-child {
  margin-bottom: 0;
}

.menu__link {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  border-bottom: 1px solid #0000;
  padding-bottom: 8px;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: border-color .3s;
  display: inline-block;
}

@media (width >= 1024px) {
  .menu__link {
    font-weight: 700;
  }
}

.menu__link:hover {
  border-color: #fff;
}

.menu__call {
  text-transform: uppercase;
  color: #fff;
  width: max-content;
  margin: auto;
  font-weight: 600;
  text-decoration: none;
  display: block;
}

@media (width >= 1024px) {
  .menu__call {
    font-weight: 700;
  }
}

.menu__call--phone {
  padding-bottom: 16px;
  line-height: 27px;
}

.menu__call--action {
  letter-spacing: 2px;
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  transition: border-bottom .3s;
}

.menu__call--action:hover {
  border-color: #0000;
}

.advantages__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.advantages__title {
  text-align: center;
  margin: 0 10px 30px;
}

@media (width >= 1024px) {
  .advantages__title {
    margin: 0 20px 50px;
  }
}

.advantages__cards {
  flex-flow: wrap;
  justify-content: center;
  max-width: 1040px;
  margin: 0 auto 20px;
  display: flex;
}

.advantages__card {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  padding: 15px;
}

@media (width >= 640px) {
  .advantages__card {
    width: 50%;
  }
}

@media (width >= 1024px) {
  .advantages__card {
    width: 33.3333%;
    padding: 0 15px;
  }
}

.card {
  flex-direction: column;
  align-items: start;
  display: flex;
}

.card__image {
  height: 46px;
  margin-bottom: 12px;
}

@media (width >= 640px) {
  .card__image {
    margin-bottom: 16px;
  }
}

.card__title {
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 15px;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
}

@media (width >= 640px) {
  .card__title {
    font-size: 1.5rem;
  }
}

.card__description {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.phone-section {
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.phone-section__container {
  flex-direction: column;
  display: flex;
}

@media (width >= 1024px) {
  .phone-section__container {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.phone-section__content {
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
}

@media (width >= 1024px) {
  .phone-section__content {
    width: calc(50% - 35px);
  }
}

.phone-section__description {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 48px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.phone-section__phone-container {
  width: 100%;
  height: 330px;
  margin-bottom: -30px;
  padding-bottom: 20px;
  overflow: hidden;
}

@media (width >= 1024px) {
  .phone-section__phone-container {
    width: calc(50% - 35px);
    height: 530px;
    margin-bottom: -60px;
  }
}

.phone-section__phone {
  border-radius: 46px;
  width: 100%;
  max-width: 375px;
  height: 630px;
  margin: 0 auto;
  padding: 120px 0 55px;
  overflow: hidden;
  box-shadow: 0 15px 40px #00000024;
}

.phone {
  background-image: url("phone-white.94e5abb1.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.phone__screen {
  opacity: 0;
  margin: 0;
  padding: 0 34px 0 42px;
  transition: transform .6s ease-in-out, opacity .6s ease-in-out, -webkit-transform .6s ease-in-out;
  position: relative;
  transform: translateY(50px);
}

.phone__screen--show {
  opacity: 1;
  transform: translateY(0);
}

.phone__name {
  -webkit-font-smoothing: antialiased;
  letter-spacing: -.5px;
  margin: 2px 18px;
  padding: 0;
  font-size: .875rem;
  line-height: 1.25;
}

.phone__sms {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  background-color: #f2f2f2;
  border-radius: 12px 12px 12px 0;
  min-width: 105px;
  max-width: 100%;
  margin: 0;
  padding: 16px 18px 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  box-shadow: 6px 6px 9px #00000024;
}

.services__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .services__container {
    flex-direction: row;
    justify-content: space-between;
  }

  .services__description {
    width: calc(40% - 40px);
  }
}

.services__paragraph {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 40px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (width >= 640px) {
  .services__paragraph {
    margin-bottom: 56px;
  }
}

.services__cards {
  box-sizing: border-box;
  background: linear-gradient(90deg, #5081c159 0%, #4c8ac41f 20%, #39bad526 100%) center;
  border-radius: 64% 36% 62% 38% / 27% 66% 34% 73%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 650px;
  height: 100%;
  padding: 30px 50px;
  display: flex;
}

@media (width >= 640px) {
  .services__cards {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 16px;
    display: flex;
  }
}

@media (width >= 1024px) {
  .services__cards {
    width: calc(60% - 60px);
  }
}

.services__card {
  box-sizing: border-box;
  width: 100%;
  height: max-content;
  padding: 15px 15px 20px;
}

@media (width >= 640px) {
  .services__card {
    width: 230px;
    height: 290px;
    padding: 25px;
  }
}

@media (width >= 1024px) {
  .services__card {
    width: 45%;
    max-width: 230px;
  }
}

.service-card {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  display: flex;
  box-shadow: 0 15px 40px #00000014;
}

.service-card__image-container {
  box-sizing: border-box;
  background: linear-gradient(90deg, #5081c140 0%, #4c8ac42e 33%, #39bad529 100%) center;
  border-radius: 32% 68% 27% 73% / 37% 64% 36% 63%;
  margin-bottom: 15px;
  padding: 6px;
  display: flex;
}

@media (width <= 480px) {
  .service-card__image-container {
    width: 60px;
    margin-bottom: 10px;
  }
}

@media (width >= 481px) {
  .service-card__image-container {
    width: 70px;
    margin-bottom: 15px;
  }
}

@media (width >= 640px) {
  .service-card__image-container {
    width: 45%;
    margin-bottom: 20px;
    padding: 12px;
  }
}

.service-card__image {
  width: 100%;
  margin: auto;
}

.service-card__title {
  color: #0071c2;
  text-align: center;
  letter-spacing: -.5px;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 110%;
}

@media (width >= 481px) {
  .service-card__title {
    margin-bottom: 10px;
  }
}

@media (width >= 640px) {
  .service-card__title {
    margin-bottom: 12px;
    font-size: 22px;
  }
}

.service-card__description {
  color: #242525;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  font-size: 16px;
  line-height: 120%;
}

@media (width <= 480px) {
  .service-card__description {
    display: none;
  }
}

@media (width >= 481px) {
  .service-card__description {
    display: block;
  }
}

.our-standard {
  background-color: #0b82e3f1;
}

.our-standard__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .our-standard__container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.our-standard__image-container {
  box-sizing: border-box;
  justify-content: center;
  width: 80%;
  margin-bottom: 40px;
  display: flex;
}

@media (width >= 1024px) {
  .our-standard__image-container {
    width: calc(50% - 45px);
    margin: 0;
  }
}

.our-standard__image {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
}

@media (width >= 1024px) {
  .our-standard__image {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
}

.our-standard__content {
  box-sizing: border-box;
}

@media (width >= 1024px) {
  .our-standard__content {
    width: calc(50% - 45px);
  }
}

.our-standard__description {
  letter-spacing: -.5px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 48px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.code-block__container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (width >= 1024px) {
  .code-block__container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.code-block__content {
  box-sizing: border-box;
  width: 100%;
}

@media (width >= 1024px) {
  .code-block__content {
    width: calc(50% - 45px);
  }
}

.code-block__paragraph {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 32px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (width >= 1024px) {
  .code-block__paragraph:last-child {
    margin-bottom: 0;
  }
}

.code-block__list {
  margin: 0 0 48px;
  padding: 0;
  list-style: none;
}

.code-block__item {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0 0 0 34px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
}

.code-block__item:before {
  content: "";
  background-image: url("check.bb74bec3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 22px;
  height: 22px;
  margin-left: -34px;
  display: block;
  position: absolute;
}

.code-block__item:not(:last-child) {
  margin-bottom: 20px;
}

.code-block__link {
  color: #006fc9;
}

.code-block__link:hover {
  text-decoration: none;
}

.code-block__code-container {
  box-sizing: border-box;
  background-color: #0a273d;
  border: 1px solid #ffffff80;
  border-radius: 20px;
  width: 100%;
  height: max-content;
  padding: 20px 30px;
}

@media (width >= 1024px) {
  .code-block__code-container {
    width: calc(50% - 45px);
  }
}

.code-block__code-title {
  vertical-align: top;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  background: #10456c;
  border-radius: 3px;
  width: max-content;
  padding: 6px 12px;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1.5;
}

.code-block__code-snippet {
  color: #1d7ec5;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -.5px;
  word-wrap: break-word;
  max-width: 100%;
  padding: 5px 10px;
  font-family: Consolas, Liberation Mono, Courier, monospace;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  overflow-x: auto;
}

.code-block__text-yellow {
  color: #ffbe3c;
}

.everything-you-need {
  background-color: #f2f2f2;
}

.everything-you-need__title {
  text-align: center;
  margin: 0 10px 30px;
}

@media (width >= 1024px) {
  .everything-you-need__title {
    margin: 0 30px 50px 10px;
  }
}

.everything-you-need__cards {
  flex-flow: wrap;
  justify-content: flex-start;
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
}

.everything-you-need__card {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  flex-shrink: 0;
  align-items: start;
  width: 100%;
  margin: 0 0 10px;
  padding: 20px;
  display: flex;
  box-shadow: 0 15px 40px #00000014;
}

@media (width >= 640px) {
  .everything-you-need__card {
    box-shadow: none;
    background-color: #0000;
    border-radius: 0;
    width: 50%;
    margin-bottom: 0;
    padding: 15px;
  }
}

@media (width >= 1024px) {
  .everything-you-need__card {
    width: 33.3333%;
  }
}

.everything-you-need__card-title {
  box-sizing: border-box;
  text-align: left;
  color: #000;
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  background-color: #0000;
  border: 0;
  width: 100%;
  padding: 0 26px 0 0;
  font-family: inherit;
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
  position: relative;
}

@media (width >= 640px) {
  .everything-you-need__card-title {
    padding: 0;
    font-size: 1.5rem;
  }
}

.everything-you-need__card-description {
  letter-spacing: -.5px;
  color: #242525;
  -webkit-font-smoothing: antialiased;
  margin: 15px 0 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.faq {
  background-color: #0b82e3f1;
}

.faq__container {
  flex-direction: column-reverse;
  justify-content: center;
  display: flex;
}

@media (width >= 1024px) {
  .faq__container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.faq__content {
  box-sizing: border-box;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

@media (width >= 1024px) {
  .faq__content {
    width: calc(50% - 50px);
    margin: 0;
  }
}

.faq__image-container {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 40px 40px 0;
  display: flex;
}

@media (width >= 1024px) {
  .faq__image-container {
    width: calc(45% - 45px);
    margin: 0;
  }
}

.faq__image {
  height: 40vh;
  max-height: 600px;
}

@media (width >= 1024px) {
  .faq__image {
    height: 100%;
  }
}

.faq__item {
  margin: 0 0 32px;
}

.faq__item:last-child {
  margin: 0;
}

.faq__question {
  box-sizing: border-box;
  text-align: left;
  color: #fff;
  letter-spacing: -1px;
  -webkit-font-smoothing: antialiased;
  background-color: #0000;
  border: 0;
  width: 100%;
  padding: 0 26px 0 0;
  font-family: inherit;
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
  position: relative;
}

@media (width >= 640px) {
  .faq__question {
    padding-right: 40px;
    font-size: 1.5rem;
  }
}

.faq__answer {
  letter-spacing: -.5px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 15px 0 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (width >= 640px) {
  .faq__answer {
    padding-right: 40px;
  }
}

@media (width >= 1024px) {
  .contact-us {
    background-image: url("shadow-contact-us.6a5cc59d.svg");
    background-position: 100% 95%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.contact-us__container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (width >= 1024px) {
  .contact-us__container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.contact-us__left {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .contact-us__left {
    align-items: start;
    width: calc(40% - 20px);
  }
}

.contact-us__title {
  text-align: center;
  max-width: 850px;
}

@media (width >= 640px) {
  .contact-us__title {
    margin-bottom: 20px;
  }
}

@media (width >= 1024px) {
  .contact-us__title {
    text-align: left;
    margin-bottom: 40px;
  }
}

.contact-us__image {
  display: none;
}

@media (width >= 1024px) {
  .contact-us__image {
    width: 100%;
    max-width: 360px;
    margin-bottom: -30px;
    display: block;
  }
}

.contact-us__form {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

@media (width >= 640px) {
  .contact-us__form {
    background-image: url("ellipse-background.13fa9d2a.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 800px;
    padding: 40px 60px;
  }
}

@media (width >= 1024px) {
  .contact-us__form {
    width: calc(60% - 30px);
    margin: -30px 0 0;
    padding: 40px 50px;
    position: relative;
  }

  .contact-us__form:after {
    content: "";
    background-image: url("cactus-contact-us.92af5843.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 200px;
    display: block;
    position: absolute;
    bottom: 0;
    right: -30px;
  }
}

.form__body {
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding: 35px;
  display: flex;
  box-shadow: 0 6px 30px #00000024;
}

@media (width >= 1024px) {
  .form__body {
    min-width: 400px;
    max-width: 470px;
  }
}

.form__body:after {
  content: "";
  opacity: 0;
  visibility: hidden;
  background: #333333e6 url("loading.b6673a1b.gif") center / 50px no-repeat;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.form__body._sending:after {
  opacity: 1;
  visibility: visible;
}

.form__item {
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  margin: 3px 5px;
  padding: 10px 2px 2px;
  position: relative;
}

.form__item--first-name {
  width: calc(50% - 10px);
  padding-right: 5px;
  display: inline;
}

.form__item--last-name {
  width: calc(50% - 10px);
  padding-left: 5px;
  display: inline;
}

.form__label {
  box-sizing: border-box;
  z-index: 2;
  text-align: left;
  color: #666;
  letter-spacing: 0;
  background: #fff;
  width: auto;
  padding: 2px 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 15px;
}

.form__input {
  box-sizing: border-box;
  color: #242525;
  letter-spacing: 0;
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 12px 14px;
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.333;
}

.form__input--textarea {
  resize: vertical;
  min-height: 120px;
}

.form__input._error {
  border: 1px solid red;
}

.form__select {
  box-sizing: border-box;
  appearance: none;
  color: #242525;
  letter-spacing: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+") 95% / 12px no-repeat;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 12px 24px 12px 14px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.333;
}

.form__button {
  color: #fff;
  background-color: #0078db;
  margin: 10px 5px;
}

.form__button:before {
  content: "";
  background-color: #fff;
}

.form__button:hover {
  color: #0078db;
}

/*# sourceMappingURL=index.5cbd38aa.css.map */
