$colorBlue: #0078db;
$colorText: #242525;  //треба змінити!!!!

$colorBackgroundLight: #F2F2F2;  //треба змінити!!!!
// $colorBackgroundLight: #edeff4d3; 
// $colorBackgroundLight: #4091FF10; 

// $colorBackgroundDark: #0078db; 
// $colorBackgroundDark: #017de2; 
// $colorBackgroundDark: #1085e4e8; 
$colorBackgroundDark: #0b82e3f1; 
// $colorBackgroundDark: #03b7f8; 
// $colorBackgroundDark: #03a2f8; 
// $colorBackgroundDark: #0087cf; 

$headerTopPaddingMobile: 26px;
$headerTopPaddingTablet: 30px;
// $horisontalMarginHeader: 40px;

$columnsTablet: 8;
$columnsDesktop: 12;