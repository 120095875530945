.container {
  margin: 0 auto; 
  max-width: 1200px;
  padding: 0 20px;

  @include onTablet {
    padding: 0 30px;
  }

  @include onDesktop {
    padding: 0 40px;
  }
}
