.icon {
  position: relative;

  display: block;
  width: 100%;
  padding-bottom: 100%;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none;

  &--phone {
    background-image: url(../images/phone.svg);
  }

  &__tooltip {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);

    display: block;
    width: max-content;
    padding-right: 18px;

    font-weight: 700;
    letter-spacing: 1px;
    color: $colorBlue;
    text-transform: uppercase;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  &:hover &__tooltip {
    opacity: 1;
    pointer-events: all;
  }

  &--menu {
    background-image: url(../images/menu.svg);

    &:hover {
      background-image: url(../images/menu-hover.svg);
    }
  }

  &--cross {
    grid-column: -2 / -1;
    background-image: url(../images/cross.svg);
  }

  &--chat {
    width: 26px;
    height: 26px;
    padding: 0;
    background-image: url(../images/chat.svg);
    position: absolute;
    // left: 10%;
    // top: 50%;
    transform: translate(-32px, 18px);
  }
}