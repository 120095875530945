.button {
  box-sizing: border-box;
  display: inline-block;
  // display: flex;
  padding: 0.9rem 1.8rem;
  padding: 14px 28px;
  width: max-content;

  font: inherit;
  font-weight: 700;
  color: $colorBlue;

  border: 2px solid $colorBlue;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;

  transition: all .3s;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlue;
    transform: translateX(-100%);
    transition: all .3s;
    z-index: -1;
  }

  &:hover {
    color: #fff;
  }

  &:hover::before {
    transform: translateX(0);
  }

  &--light {
    // color: #fff;
    border-color: #fff;
    background-color: #fff;
    // background-color: #F9EC75;
    // background-color: #ffee33;
  }
}