.everything-you-need {
  background-color: $colorBackgroundLight;

  &__title {
    text-align: center;
    margin: 0 10px 30px 10px;

    @include onDesktop {
      margin: 0 30px 50px 10px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 1040px;
    margin: 0 auto;
  }

  &__card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-shrink: 0;
    padding: 20px;
    margin: 0px 0px 10px 0px;
    width: 100%;

    // background-color: #fbfbfb;
    background-color: #fff;
    border-radius: 10px;
  
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.08);

    @include onTablet {
      padding: 15px;
      margin-bottom: 0;
      width: calc(100% / 2);
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
    }

    @include onDesktop {
      width: calc(100% / 3);
    }
  }

  &__card-title {
    position: relative;
    padding: 0 26px 0 0;
    width: 100%;

    border: 0;
    box-sizing: border-box;

    text-align: left;
    font-family: inherit;
    font-size: 22px;
    font-weight: 400;
    // color: #004278;
    color: #000;
    line-height: 1;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;

    @include onTablet {
      font-size: 1.5rem;
      padding: 0;
    }
  }

  &__card-description {
    @include paragraphText;
    margin-top: 15px;
  }
}