.page {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000;
  // -webkit-font-smoothing: antialiased;

  scroll-behavior: smooth;

  &__body {
    margin: 0;

    background-image: url(../images/header-background.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top right;

    @include onDesktop {
      background-size: 55%;
    }

    &--with-menu {
      overflow: hidden;
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    transform: translateX(-100%);
    transition: transform 0.3s;

    &:target {
      transform: translateX(0);
    }
  }

  &__section {
    padding: 30px 0;
    // margin-bottom: 60px;

    @include onDesktop {
      // margin-bottom: 100px;
      padding: 30px 0 45px;
    }

    @include onDesktop {
      // margin-bottom: 100px;
      padding: 60px 0;
      // padding: 70px 0;
      margin: 0 30px;
    }
  }

  &__section-title {
    margin: 0 0 32px;
    padding: 0;
    text-rendering: optimizeLegibility;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1px;
    // -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;

    @include onTablet {
      font-size: 2.625rem;
    }

    &--white {
      color: #fff;
    }
  }
}