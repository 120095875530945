.card {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__image {
    margin-bottom: 12px;
    height: 46px;

    @include onTablet {
      margin-bottom: 16px;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    // text-rendering: optimizeLegibility;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1px;
    // -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;

    @include onTablet {
      // margin-bottom: 15px;
      font-size: 1.5rem;
    }
  }

  &__description {
    @include paragraphText;
  }
}