// .contact-us {

//   &__container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }

//   &__title {
//     text-align: center;

//     @include onTablet {
//       margin-bottom: 20px;
//     }
//   }
// }

.contact-us {
  @include onDesktop {

    background-image: url(../images/shadow-contact-us.svg);
    background-position: 100% 95%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include onDesktop {
      align-items: start;
      width: calc((100% - 50px) * 0.4);
    }
  }

  &__title {
    text-align: center;
    max-width: 850px;

    @include onTablet {
      margin-bottom: 20px;
    }

    @include onDesktop {
      margin-bottom: 40px;
      text-align: left;
    }
  }

  &__image {
    display: none;

    @include onDesktop {
      display: block;
      width: 100%;
      max-width: 360px;

      margin-bottom: -30px;
    }
  }

  &__form {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;

    @include onTablet {
      max-width: 800px;
      padding: 40px 60px;

      background-image: url(../images/ellipse-background.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    @include onDesktop {
      position: relative;;
      width: 100%;
      padding: 40px 50px;
      margin: 0;
      margin-top: -30px;
      width: calc((100% - 50px) * 0.6);

      &::after {
        content: '';
        position: absolute;
        right: -30;
        bottom: 0;
        display: block;
        // padding-bottom: 100%;
        width: 120px;
        height: 200px;
        background-image: url(../images/cactus-contact-us.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}